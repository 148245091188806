<biosimulations-navigation
  *ngIf="!isMobileDispatch; else mobileDispatch"
  [appName]="config.appName"
  [appNameParts]="config.appNameParts"
  [logo]="config.logo"
  [privacyPolicyVersion]="config.privacyPolicyVersion"
  [newsVersion]="1"
  [showNews]="false"
  [healthy]="healthy$ | async">
  <!-- Start Hero Banner -->
  <biosimulations-topbar-menu>
    <biosimulations-hover-open-menu
      [disabled]="true"
      [href]="appRoutes.platformApp"
      matTooltip="Browse simulation projects">
      <biosimulations-topbar-menu-item heading="BioSimulations" icon="browse" id="trigger">
      </biosimulations-topbar-menu-item>
    </biosimulations-hover-open-menu>

    <biosimulations-hover-open-menu>
      <biosimulations-topbar-menu-item heading="Run Simulations" icon="experiment" id="trigger">
      </biosimulations-topbar-menu-item>
      <ng-container id="items">
        <biosimulations-dropdown-menu-item
          heading="Browse Simulation Runs"
          icon="browse"
          id="trigger"
          [routerLink]="['/runs']">
        </biosimulations-dropdown-menu-item>
        <biosimulations-dropdown-menu-item
          heading="Run a Simulation"
          icon="simulation"
          id="trigger"
          [routerLink]="['/runs', 'new']">
        </biosimulations-dropdown-menu-item>
      </ng-container>
    </biosimulations-hover-open-menu>

    <biosimulations-hero-banner-util-button></biosimulations-hero-banner-util-button>

    <biosimulations-hover-open-menu
      [disabled]="true"
      [href]="appRoutes.simulatorsApp"
      target="_blank"
      matTooltip="Browse simulator tools">
      <biosimulations-topbar-menu-item heading="BioSimulators" icon="link" id="trigger">
      </biosimulations-topbar-menu-item>
    </biosimulations-hover-open-menu>
  </biosimulations-topbar-menu>

  <ng-container id="news"></ng-container>
  <router-outlet></router-outlet>
</biosimulations-navigation>
<biosimulations-app-footer></biosimulations-app-footer>

<ng-template #mobileDispatch>
  <biosimulations-navigation
    [appName]="config.appName"
    [appNameParts]="config.appNameParts"
    [logo]="config.logo"
    [privacyPolicyVersion]="config.privacyPolicyVersion"
    [newsVersion]="1"
    [showNews]="false"
    [healthy]="healthy$ | async">
    <biosimulations-topbar-menu></biosimulations-topbar-menu>
    <ng-container id="news"> Under Construction </ng-container>
    <router-outlet></router-outlet>
  </biosimulations-navigation>
</ng-template>
